import { createSlice, PayloadAction } from '@reduxjs/toolkit'



export const counterSlice = createSlice({
  name: 'user',
  initialState: {
      userToken: null
  },
  reducers: {
    login: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.userToken = action.payload
    },
    logout: (state) => {
      state.userToken = null
    },
    // incrementByAmount: (state, action) => {
    //   state.value += action.payload
    // },
  },
})

// Action creators are generated for each case reducer function
export const { login, logout } = counterSlice.actions

export default counterSlice.reducer