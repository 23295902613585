

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
    Table,
    Badge,
} from "reactstrap";
import { enviroment } from "variables/enviroment";

function Dashboard() {
    var Spinner = require('react-spinkit');
    const [token, settoken] = useState(null)
    const [dashboard, setdashboard] = useState(null)
    const [isLoading, setisLoading] = useState(false)
    const [error, seterror] = useState(null)

    //Route hook
    const history = useHistory()

    useEffect(() => {
        const item = document.getElementsByClassName('table-responsive')
        item[0]?.classList.add('provider-table')
    }, [dashboard])


    useEffect(() => {
        getToken()
        return () => {
            getToken()
        }
    }, [])

    const getToken = () => {
        const user = localStorage.getItem("user");

        if (!user) return;

        const formatItem = JSON.parse(user);

        if (formatItem?.token) {
            settoken(formatItem?.token)
            getDashboard(formatItem?.token)
        }
    }

    const getDashboard = (token) => {
        setisLoading(true)
        seterror(null)

        const header = new Headers();
        header.append("Authorization", `Bearer ${token}`);
        header.append("Accept", "application/json");

        const payload = {
            method: 'GET',
            headers: header,
            redirect: 'follow'
        };

        fetch(enviroment.BASE_URL + "channel/dashboard", payload)
            .then(response => {
                return response.text()
            })
            .then(result => {
                setisLoading(false)
                const item = JSON.parse(result)
                console.log("dashboard", item);
                setdashboard(item.data)
            })
            .catch(error => {
                seterror(error)
                console.log('error', error)
            });
    }

    return (
        <>
            <div className="content">
                {isLoading && (
                    <Spinner name='circle' color="#663391" fadeIn="none" />
                )}

                {dashboard && (
                    <>
                        <Row>
                            <Col lg="3" md="6" sm="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <Col md="4" xs="5">
                                                <div className="icon-big text-center icon-warning">
                                                    <i className="nc-icon nc-globe text-warning" />
                                                </div>
                                            </Col>
                                            <Col md="8" xs="7">
                                                <div className="numbers">
                                                    <p className="card-category">Agents</p>
                                                    <CardTitle tag="p">{dashboard.agentCount}</CardTitle>
                                                    <p />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr />
                                        <div style={{ cursor: "pointer" }} onClick={() => history.push('/admin/agents')} className="stats">
                                            <i className="fas fa-sync-alt" /> View Agents
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col lg="3" md="6" sm="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <Col md="4" xs="5">
                                                <div className="icon-big text-center icon-warning">
                                                    <i className="nc-icon nc-money-coins text-success" />
                                                </div>
                                            </Col>
                                            <Col md="8" xs="7">
                                                <div className="numbers">
                                                    <p className="card-category">Orders</p>
                                                    <CardTitle tag="p">4119</CardTitle>
                                                    <p />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr />
                                        <div style={{ cursor: "pointer" }} onClick={() => history.push('/admin/orders')} className="stats">
                                            <i className="fas fa-sync-alt" /> View Orders
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <Card>

                                    <CardHeader className="d-flex justify-content-between">
                                        <p className="">Orders</p>
                                        <p style={{ cursor: "pointer" }} onClick={() => history.push('/admin/orders')} className="">View Orders</p>

                                    </CardHeader>
                                    <CardBody>
                                        <Table responsive>
                                            <thead className="text-primary">
                                                <tr>
                                                    <th>Ref</th>
                                                    <th>Amount</th>
                                                    <th>Plan</th>
                                                    <th>Type</th>
                                                    <th>Payment Status</th>
                                                    <th>Enrollement Status</th>
                                                    <th className="text-right">Created At</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dashboard.orders?.slice(0, 10).map((order) => (
                                                    <tr key={order.order_id} className="plans-row">
                                                        <td onClick={() => { history.push('/admin/orders/' + order.order_id) }}>{order.order_ref}</td>
                                                        <td onClick={() => { history.push('/admin/orders/' + order.order_id) }}>N{order.order_amount}</td>
                                                        <td onClick={() => { history.push('/admin/orders/' + order.order_id) }}>N{order.order_amount}</td>
                                                        <td onClick={() => { history.push('/admin/orders/' + order.order_id) }}>{order.order_type}</td>
                                                        <td onClick={() => { history.push('/admin/orders/' + order.order_id) }}>
                                                            {order.order_status === "paid" ? (
                                                                <Badge color="success">{order.order_status}</Badge>
                                                            ) : (
                                                                <Badge>{order.order_status}</Badge>
                                                            )}
                                                        </td>
                                                        <td onClick={() => { history.push('/admin/orders/' + order.order_id) }}>
                                                            {order.order_status === "paid" && <Badge color="success">Enrolled</Badge>}
                                                            {order.order_status === "paid" && order.order_processed === 0 ? <Badge color="success">PENDING</Badge> : null}
                                                            {order.order_status === "paid" && order.order_processed === 1 ? <Badge color="success">Enrolled</Badge> : null}
                                                        </td>
                                                        <td className="text-right">
                                                            {new Date(order.create_time).toLocaleDateString("en-NG",
                                                                {
                                                                    year: "numeric",
                                                                    day: "numeric",
                                                                    month: "long",
                                                                }
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </>
                )}
            </div>
        </>
    );
}

export default Dashboard;
