import React, { useState, useEffect } from "react";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Table,
    Row,
    Col,
    Input,
    Button,
} from "reactstrap"; // http
import NotificationAlert from "react-notification-alert";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { enviroment } from './../../variables/enviroment';
import ReactPaginate from 'react-paginate';

const Admins = () => {
    var Spinner = require('react-spinkit');
    const notificationAlert = React.useRef();
    const [isLoading, setisLoading] = useState(false);
    const [error, seterror] = useState(null)
    const [token, setToken] = useState(null)

    const [admins, setadmins] = useState(null)

    const [pageCount, setpageCount] = useState(0)
    const [totalPage, settotalPage] = useState(0)
    const [agentId, setAgentId] = useState('');
    const [agentName, setAgentName] = useState('');
    const [agentNumber, setAgentNumber] = useState('');
    const [agentEmail, setAgentEmail] = useState('');

    useEffect(() => {
        getToken();
        return () => {
            getToken();
        }
    }, []);

    useEffect(() => {
        const item = document.getElementsByClassName('table-responsive')
        item[0]?.classList.add('provider-table')
    }, [admins])

    useEffect(() => {
        getAdmins(token);
    }, [token]);

    useEffect(() => {
        getAdmins();
    }, [pageCount])

    const getToken = () => {
        const user = localStorage.getItem("user");

        if (!user) return;

        const formatItem = JSON.parse(user);

        if (formatItem?.token) {
            setToken(formatItem?.token);
            // getAdmins(formatItem?.token)
        }
    }

    // get all admins
    const getAdmins = () => {
        setisLoading(true)
        seterror(null);

        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(
            `${enviroment.BASE_URL}channel/agents?page=${pageCount ?? 1}&phone=${agentNumber ?? ''}&email=${agentEmail ?? ''}&agentId=${agentId ?? ''}&agentName=${agentName ?? ''}`,
            requestOptions
        )
            .then(response => {
                setisLoading(false)
                return response.text()
            })
            .then(result => {
                const item = JSON.parse(result)
                setadmins(item.data?.data);
                setpageCount(item.data?.current_page);
                settotalPage(item.data?.last_page);
            })
            .catch(error => {
                seterror(error)
            });
    };

    const handlePageChange = (data) => {
        setpageCount(data.selected + 1);
    }

    const filterAgents = () => {
        setpageCount(1);
        getAdmins(); // call fetch agents
    }

    return (
        <>
            <div className="content">
                <NotificationAlert ref={notificationAlert} />
                <Row>
                    <Col md="12">
                        {isLoading && (
                            <Spinner name='circle' color="#663391" fadeIn="none" />
                        )}

                        {admins && (
                            <>
                                <Card>

                                    <Row>
                                        <Col md="12" style={{ margin: 15 }}>
                                            <Input placeholder="Filter by agent ID" className="filter-input" value={agentId} onChange={(e) => setAgentId(e.target.value)} />
                                            <Input placeholder="Filter by agent name" className="filter-input" value={agentName} onChange={(e) => setAgentName(e.target.value)} />
                                            <Input placeholder="Filter by agent phone" className="filter-input" value={agentNumber} onChange={(e) => setAgentNumber(e.target.value)} />
                                            <Input placeholder="Filter by agent email" className="filter-input" value={agentEmail} onChange={(e) => setAgentEmail(e.target.value)} />
                                            <Button color="info" className="filter-button" onClick={filterAgents}>Filter</Button>
                                        </Col>
                                    </Row>

                                    <CardBody>
                                        <Table responsive>
                                            <thead className="text-primary">
                                                <tr>
                                                    <th>Full Name</th>
                                                    <th>Phone Number</th>
                                                    <th>Email</th>
                                                    <th>State</th>
                                                    <th>Link</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {admins.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {item.agent_lastname}
                                                        </td>
                                                        <td>{item.agent_phone}</td>
                                                        <td>{item.agent_email}</td>
                                                        <td>
                                                            {item.agent_state}
                                                        </td>
                                                        <td>
                                                            {item.agent_link}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                                <ReactPaginate
                                    previousLabel={'previous'}
                                    nextLabel={'next'}
                                    pageCount={totalPage}
                                    onPageChange={handlePageChange}
                                    containerClassName={'pagination justify-content-center'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    previousClassName={'page-item'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    previousLinkClassName={'page-link'}
                                    activeClassName={'active'}
                                ></ReactPaginate>
                            </>
                        )}
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default Admins;
