import Dashboard from "views/Dashboard.js";

import Orders from "views/Orders.js";

// auth
import Signin from "views/auths/Signin";

// admins
import Admins from "views/admins/Admins";
import OrderDetails from "views/sections/orders/orderDetails";
import Logout from "views/Logout";


var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/orders",
    name: "Orders",
    icon: "nc-icon nc-tile-56",
    component: Orders,
    layout: "/admin",
  },
  {
    path: "/agents",
    name: "Agents",
    icon: "nc-icon nc-circle-10",
    component: Admins,
    layout: "/admin",
  },
  {
    path: "/logout",
    name: "Logout",
    icon: "nc-icon nc-settings-gear-65",
    component: Logout,
    layout: "/admin",
  },
  {
    path: "/orders/:id",
    name: "Order Details",
    icon: "nc-icon nc-pallete",
    component: OrderDetails,
    layout: "/admin",
    display: false,
  },

  // auth 
  {
    path: "/signin",
    name: "Admin Signin",
    icon: "nc-icon nc-circle-10",
    component: Signin,
    layout: "/auth",
    display: false,
  },
];
export default routes;
